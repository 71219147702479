import { Link, Paper, withStyles } from "@material-ui/core";
import * as React from "react";
import { FormattedHTMLMessage } from "react-intl";
import styled from "styled-components";
import { t } from "../../i18n/util";
import { Badge, IBadgeColors, IBadgeValue } from "../util/Badge";
import { Icon } from "../util/Icon";

const StyledPaper = withStyles({
    root: {
        background: "#FFFFFF",
        boxShadow: "0 -2px 10px 0 rgba(124,126,136,0.10), 0 8px 10px 0 rgba(101,121,222,0.05)",
        borderRadius: 4,
        width: 360,
        padding: "32px 24px",
        color: "#1E1E1E",
    },
})(Paper);

const BadgeContainer = styled.div`
    display: flex;
    align-items: flex-start;
    line-height: 16px;
    font-size: 14px;
    color: #878787;
    & > * + * {
        margin-left: 8px;
    }
`;

const BadgeInfo = ({
    value,
    color,
    children,
}: {
    value: IBadgeValue;
    color?: IBadgeColors;
    children: React.ReactNode;
}) => (
    <BadgeContainer>
        <div>
            <Badge value={value} color={color} size="small" style={{ display: "block" }} />
        </div>
        <span>{children}</span>
    </BadgeContainer>
);

const Heading = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 32px;
`;

const Section = styled.div`
    margin-bottom: 24px;
`;

const SectionText = styled.div`
    margin-bottom: 8px;
`;

const Divider = styled.div`
    height: 2px;
    background-color: #e1e1e1;
    margin-bottom: 16px;
`;

const InfoContainer = styled.div`
    align-items: center;
    font-size: 14px;
`;

const BadgeFlex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const BadgeVerticalFlex = styled.div`
    display: flex;
    flex-direction: column;
    & > * + * {
        margin-top: 8px;
    }
`;

const LegendInfo = styled.div`
    font-size: 12px;
    color: #878787;
    margin-bottom: 24px;
`;

export const Legend = () => (
    <div>
        <StyledPaper data-id={"optimizer_legend"}>
            <Heading>
                <Icon name="info" style={{ marginRight: 8 }} />
                <b>{t("optimizer.legend.heading")}</b>
            </Heading>
            <Section>
                <SectionText>{t("optimizer.legend.section.okay.text")}</SectionText>
                <BadgeInfo value="okay">{t("optimizer.legend.okay.text")}</BadgeInfo>
            </Section>
            <Section>
                <SectionText>{t("optimizer.legend.section.decrease.text")}</SectionText>
                <BadgeFlex>
                    <BadgeInfo value="decreaseLow">{t("optimizer.legend.decrease.low.text")}</BadgeInfo>
                    <BadgeInfo value="decreaseMedium">{t("optimizer.legend.decrease.medium.text")}</BadgeInfo>
                    <BadgeInfo value="decreaseHigh">{t("optimizer.legend.decrease.high.text")}</BadgeInfo>
                </BadgeFlex>
            </Section>
            <Section>
                <SectionText>{t("optimizer.legend.section.increase.text")}</SectionText>
                <BadgeFlex>
                    <BadgeInfo value="increaseLow">{t("optimizer.legend.increase.low.text")}</BadgeInfo>
                    <BadgeInfo value="increaseMedium">{t("optimizer.legend.increase.medium.text")}</BadgeInfo>
                    <BadgeInfo value="increaseHigh">{t("optimizer.legend.increase.high.text")}</BadgeInfo>
                </BadgeFlex>
            </Section>
            <Section>
                <SectionText>{t("optimizer.legend.section.warnings.text")}</SectionText>
                <BadgeVerticalFlex>
                    <BadgeInfo value="danger">{t("optimizer.legend.section.not_recommended.badge.text")}</BadgeInfo>
                    <BadgeInfo value="warning" color="gray">
                        {t("optimizer.legend.section.warning.badge.text")}
                    </BadgeInfo>
                </BadgeVerticalFlex>
            </Section>
            <Section>
                <SectionText>{t("optimizer.legend.section.other.text")}</SectionText>
                <BadgeVerticalFlex>
                    <BadgeInfo value="notTested">{t("optimizer.legend.notTested.badge.text")}</BadgeInfo>
                    <BadgeInfo value="unknown">{t("optimizer.legend.unknown.badge.text")}</BadgeInfo>
                </BadgeVerticalFlex>
            </Section>
            <Divider />
            <InfoContainer>
                <LegendInfo>
                    <FormattedHTMLMessage id="optimizer.legend.info" />
                </LegendInfo>
                {t("optimizer.legend.information.text")}&nbsp;
                <b>
                    <Link underline="none" href="https://cpicpgx.org/guidelines/" target="_blank">
                        {t("optimizer.legend.information.cpic")}
                    </Link>
                </b>
            </InfoContainer>
        </StyledPaper>
    </div>
);
